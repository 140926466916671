import Vue from 'vue';
import Router, { Route } from 'vue-router';

// Dynamically import the routes so webpack can chunk the app in smaller pieces
const Tool = () => import('@/views/Tool.vue');
const Home = () => import('@/views/Home.vue');
const NotFound = () => import('@/views/NotFound.vue');
const Account = () => import('@/views/Account/Account.vue');
const InlenersList = () => import('@/views/Inleners/InlenersList.vue');
const InlenerDetail = () => import('@/views/Inleners/InlenerDetail.vue');
const InlenersbeloningDetail = () => import('@/views/Inleners/InlenersbeloningDetail.vue');
const AccountCaos = () => import('@/views/Account/AccountCaos.vue');
const ChangePassword = () => import('@/views/ChangePassword.vue');
const ForgotPassword = () => import('@/views/ForgotPassword.vue');
const Login = () => import('@/views/Login.vue');
const Register = () => import('@/views/Register.vue');
const RegisterNbbu = () => import('@/views/RegisterNbbu.vue');
const News = () => import('@/views/News.vue');
const Verhogingen = () => import('@/views/Verhogingen.vue');
const NewsItem = () => import('@/views/NewsItem.vue');
const UitzendBevestiging = () => import('@/views/UitzendBevestiging.vue');
const Verify = () => import('@/views/Verify.vue');
const VerifyInvitation = () => import('@/views/VerifyInvitation.vue');
const ResetPassword = () => import('@/views/ResetPassword.vue');
const Prices = () => import('@/views/Prices.vue');
const TermsAndConditions = () => import('@/views/TermsAndConditions.vue');
const PrivacyAndCookies = () => import('@/views/PrivacyAndCookies.vue');
const Faq = () => import('@/views/Faq.vue');
const Contact = () => import('@/views/Contact.vue');
const InlenersBeloning = () => import('@/views/InlenersBeloning.vue');
const AbuWijziging = () => import('@/views/AbuWijziging.vue');
const KennisBank = () => import('@/views/KennisBank.vue');
const KennisBankCaos = () => import('@/views/KennisBankCaos.vue');
const Caos = () => import('@/views/Caos.vue');
const KoppelingAlgemeen = () => import('@/views/KoppelingAlgemeen.vue');
const KoppelingMysolution = () => import('@/views/KoppelingMysolution.vue');
const Application = () => import('@/views/Application.vue');
const TrialPromo = () => import('@/views/TrialPromo.vue');
const TrialApi = () => import('@/views/TrialApi.vue');
const TrialSupplier = () => import('@/views/TrialSupplier.vue');
const CaoComplianceDetail = () => import('@/views/Inleners/CaoComplianceDetail.vue');

import store from '@/store/index.ts';

Vue.use(Router);

const requireLogin = (to: Route, from: Route, next: (to?: string) => void) => {
  if (!store.getters['account/isLoggedIn']) {
    next('login');
  } else {
    next();
  }
};

const requireAdmin = (to: Route, from: Route, next: (to?: string) => void) => {
  if (!store.getters['account/isLoggedIn'] || !store.getters['account/userInfo'].isAdmin) {
    next('login');
  } else {
    next();
  }
};


const requirePdfAllowed = (to: Route, from: Route, next: (to?: string) => void) => {
  if (!store.getters['account/isLoggedIn']) {
    next('login');
  } else if (!store.getters['account/pdfAllowed']) {
    next('prices');
  } else {
    next();
  }
};

export default new Router({
  mode: 'history',
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) { // Scroll to hashed id's when given in route
      return {selector: to.hash};
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    {
      path: '/',
      name: 'default',
      component: Home,
    },
    {
      path: '/application-hr-medewerker',
      name: 'application',
      component: Application,
    },
    {
      path: '/account',
      name: 'account',
      component: Account,
      beforeEnter: requireLogin, // TODO: use guards instead
    },
    {
      path: '/inleners-list',
      name: 'inleners-list',
      component: InlenersList,
      beforeEnter: requireLogin, // TODO: use guards instead
    },
      {
      path: '/inleners/:subscription_id',
      name: 'inlener-detail',
      component: InlenerDetail,
      beforeEnter: requireLogin, // TODO: use guards instead
    },
    {
      path: '/inleners/:subscription_id/inlenersbeloning-detail/:guid',
      name: 'inlenersbeloning-detail',
      component: InlenersbeloningDetail,
      beforeEnter: requireLogin, // TODO: use guards instead
    },
    {
      path: '/inleners/:subscription_id/cao-compliance-detail/:id',
      name: 'cao-compliance-detail',
      component: CaoComplianceDetail,
      beforeEnter: requireLogin, // TODO: use guards instead
    },
    {
      path: '/account-caos',
      name: 'account-caos',
      component: AccountCaos,
      beforeEnter: requireAdmin, // TODO: use guards instead
    },
    {
      path: '/verhogingen',
      name: 'verhogingen',
      component: Verhogingen,
    },
    {
      path: '/news',
      name: 'news',
      component: News,
    },
    {
      path: '/news-item/:id',
      name: 'news-item',
      component: NewsItem,
    },
    {
      path: '/change-password',
      name: 'change-password',
      component: ChangePassword,
    },
    {
      path: '/caos',
      name: 'caos',
      component: Caos,
    },
    {
      path: '/koppelingen/mysolution/caos',
      name: 'koppelingen-mysolution',
      component: KoppelingMysolution,
    },
    {
      path: '/koppelingen/algemeen/caos',
      name: 'koppelingen-algemeen',
      component: KoppelingAlgemeen,
    },
    {
      path: '/kennis-bank',
      name: 'kennis-bank',
      component: KennisBank,
      beforeEnter: requireLogin,
    },
    {
      path: '/kennis-bank-caos',
      name: 'kennis-bank-caos',
      component: KennisBankCaos,
      beforeEnter: requireLogin,
    },
    {
      path: '/contact',
      name: 'contact',
      component: Contact,
    },
    {
      path: '/inleners-beloning',
      name: 'inleners-beloning',
      component: InlenersBeloning,
    },
    {
      path: '/abu-wijziging',
      name: 'abu-wijziging',
      component: AbuWijziging,
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: ForgotPassword,
    },
    {
      path: '/pricing',
      name: 'pricing',
      component: Prices,
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/login/:any',
      name: 'microsoft-login',
      component: Login,
      props: { microsoftLogin: true },
    },
    {
      path: '/login/sso/:refresh_token',
      beforeEnter: async (to: Route, from: Route, next: (to?: string) => void) => {
        store.commit('account/LOGIN', {
          refreshToken: to.params.refresh_token,
        });
        next('tool');
      },
    },
    {
      path: '/NBBU-leden',
      name: 'register-nbbu',
      component: RegisterNbbu,
    },
    {
      path: '/register',
      name: 'register',
      component: Register,
    },
    {
      path: '/terms-and-conditions',
      name: 'terms-and-conditions',
      component: TermsAndConditions,
    },
    {
      path: '/privacy-and-cookies',
      name: 'privacy-and-cookies',
      component: PrivacyAndCookies,
    },
    {
      path: '/faq',
      name: 'faq',
      component: Faq,
    },
    {
      path: '/uitzend-bevestiging',
      name: 'uitzend-bevestiging',
      component: UitzendBevestiging,
      beforeEnter: requirePdfAllowed,
    },
    {
      path: '/',
      beforeEnter: (to: Route, from: Route, next: (to?: string) => void) => {
        if (store.getters['account/isLoggedIn']) {
          next('tool');
        } else {
          next('home');
        }
      },
    },
    {
      path: '/trial',
      name: 'trial-promo',
      component: TrialPromo,
    },
    {
      path: '/trial-api',
      name: 'trial-api',
      component: TrialApi,
    },
    {
      path: '/trial-supplier',
      name: 'trial-supplier',
      component: TrialSupplier,
    },
    {
      path: '/home',
      name: 'home',
      component: Home,
    },
    {
      path: '/tool',
      name: 'tool',
      component: Tool,
    },
    {
      path: '/verify/:uid/:verifyToken',
      name: 'verify',
      component: Verify,
    },
    {
      path: '/verify-invitation/:uid/:verifyToken',
      name: 'verify-invitation',
      component: VerifyInvitation,
    },
    {
      path: '/reset-password/:uid/:resetToken',
      name: 'reset-password',
      component: ResetPassword,
    },
    {
      path: '*',
      name: 'not-found',
      component: NotFound,
    },
  ],
});
