import { AccountState } from './types';

const state: AccountState = {
  firstName: '',
  lastName: '',
  newsLetter: false,
  organizationSubscription: '',
  userName: '',
  company: '',
  token: '',
  refreshToken: '',
  email: '',
  isAdmin: false,
  ibanName: '',
  ibanNr: -1,
  kvkNr: -1,
  incassoAccepted: false,
  profileImage: '',
  daysTillEnd: -1,
  inlenerWebModule: false,
  caosTool: new Set<string>([]),
  caosAgreement: new Array<{ code: string; deactivatedAt: string }>(),
  groups: [],
};

export default state;
