import 'babel-polyfill';
import Vue, { PluginObject } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/index';
import PortalVue from 'portal-vue';
Vue.use(PortalVue);
import('vue-monthly-picker').then((module) => {
  Vue.component('vue-monthly-picker', module.default);
});
import 'cropperjs/dist/cropper.css';
import('vue-cropperjs').then((module) => {
  Vue.component('vue-cropper', module.default);
});
import('vue-wysiwyg').then((module) => {
  Vue.use(module.default as PluginObject<{}>);
});

// CSS imports
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'vue-wysiwyg/dist/vueWysiwyg.css';

import {
  BContainer,
  BRow,
  BCol,
  BForm,
  BFormCheckbox,
  BFormInput,
  BFormInvalidFeedback,
  BFormRadio,
  BFormGroup,
  BCard,
  BCardGroup,
  BCardBody,
  BCardFooter,
  BCardHeader,
  BCardTitle,
  BCardText,
  BModal,
  VBModal,
  BSpinner,
  BTooltip,
  BPagination,
} from 'bootstrap-vue';

Vue.component('b-container', BContainer);
Vue.component('b-row', BRow);
Vue.component('b-col', BCol);
Vue.component('b-form', BForm);
Vue.component('b-form-checkbox', BFormCheckbox);
Vue.component('b-form-input', BFormInput);
Vue.component('b-form-invalid-feedback', BFormInvalidFeedback);
Vue.component('b-form-radio', BFormRadio);
Vue.component('b-form-group', BFormGroup);
Vue.component('b-card-group', BCardGroup);
Vue.component('b-card', BCard);
Vue.component('b-card-body', BCardBody);
Vue.component('b-card-footer', BCardFooter);
Vue.component('b-card-header', BCardHeader);
Vue.component('b-card-title', BCardTitle);
Vue.component('b-card-text', BCardText);
Vue.component('b-modal', BModal);
Vue.directive('b-modal', VBModal);
Vue.component('b-spinner', BSpinner);
Vue.component('b-tooltip', BTooltip);
Vue.component('b-pagination', BPagination);


Vue.directive('scroll', {
  inserted: (el, binding) => {
    const f = (evt: any) => {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f);
      }
    };
    window.addEventListener('scroll', f);
  },
});

// Font awesome imports
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAlignJustify,
  faBuilding,
  faCheck,
  faPhone,
  faMapMarkerAlt,
  faEnvelope,
  faSadTear,
  faQuestionCircle,
  faAngleDown,
  faAngleUp,
  faBars,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import axios from 'axios';
import { AxiosResponse } from 'axios';

library.add(faTimes as any);
library.add(faAngleDown as any);
library.add(faAngleUp as any);
library.add(faBars as any);
library.add(faBuilding as any);
library.add(faAlignJustify as any);
library.add(faCheck as any);
library.add(faPhone as any);
library.add(faMapMarkerAlt as any);
library.add(faEnvelope as any);
library.add(faSadTear as any);
library.add(faQuestionCircle as any);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;

axios.interceptors.response.use((response: AxiosResponse) => {
  return response;
}, (error) => {
  if (error.response && error.response.status === 401) {
    store.commit('account/LOGOUT');
    router.push({ name: 'login' });
  }
  return Promise.reject(error);
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

gtag('event', 'page_view', {
  page_title: router.currentRoute.name,
  page_location: router.currentRoute.path,
  page_path: router.currentRoute.fullPath,
  send_to: 'G-547RBZ1ZK4',
});

router.afterEach(( to, from ) => {
  gtag('event', 'page_view', {
    page_title: to.name,
    page_location: to.path,
    page_path: to.fullPath,
    send_to: 'G-547RBZ1ZK4',
  });
});
